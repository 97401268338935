import React from 'react'
import { css, keyframes } from '@emotion/core'

import { Triangle, Rectangle } from './common'

import breakpoints from '../utils/breakpoints'
import LottieName from './LottieName'

const revealBottomTriangle = keyframes`
  to {
    transform: translate3d(40%, 60%, 0);
  }
`

const revealTopRightTriangle = keyframes`
  to {
    transform: translate3d(40%, -60%, 0) rotate3d(0, 0, 1, -90deg);
  }
`

const revealTopLeftTriangle = keyframes`
  to {
    transform: translate3d(-40%, -80%, 0) rotate3d(0, 0, 1, -45deg);
  }
`

const revealRectangle = keyframes`
  to {
    transform: translate3d(-40%, 80%, 0) rotate3d(0, 0, 1, -45deg);
  }
`

const Banner = ({ className }) => (
  <div
    className={className}
    css={theme => css`
      ${breakpoints.xs} {
        height: 75vw;
      }

      ${breakpoints.sm} {
        height: 480px;
      }

      height: 100vw;
      position: relative;
      background: ${theme.colors.white};
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: height 0.2s ease-out;
    `}
  >
    <Triangle
      fill="white"
      css={css`
        transform-origin: 50% 50%;
        position: absolute;
        width: 100%;
        height: auto;
        animation: ${revealBottomTriangle} 3.3s ease-out forwards;
        animation-iteration-count: 1;
        animation-delay: 0.5s;
        transform: translate3d(100%, 100%, 0);
      `}
    />
    <Triangle
      fill="white"
      css={css`
        transform-origin: 50% 50%;
        position: absolute;
        width: 100%;
        height: auto;
        animation: ${revealTopRightTriangle} 3.3s ease-out forwards;
        animation-iteration-count: 1;
        animation-delay: 0.5s;
        transform: translate3d(100%, -100%, 0);
      `}
    />
    <Triangle
      fill="white"
      css={css`
        transform-origin: 50% 50%;
        position: absolute;
        width: 100%;
        height: auto;
        animation: ${revealTopLeftTriangle} 3.3s ease-out forwards;
        animation-iteration-count: 1;
        animation-delay: 0.5s;
        transform: translate3d(-100%, -100%, 0);
      `}
    />
    <Rectangle
      fill="white"
      css={css`
        transform-origin: 50% 50%;
        position: absolute;
        width: 100%;
        height: auto;
        animation: ${revealRectangle} 3.3s ease-out forwards;
        animation-iteration-count: 1;
        animation-delay: 0.5s;
        transform: translate3d(-100%, 100%, 0);
      `}
    />
    <LottieName
      css={css`
        transform: translate3d(0, 0, 1px);
        align-self: center;
        width: 70%;
        height: initial;
        max-width: 1200px;
      `}
    />
  </div>
)

export default Banner
