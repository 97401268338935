import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const Consulting = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['steam01', 'steam02', 'steam03'])}
        ${steamAnimation(['steam01-2', 'steam02-2', 'steam03-2'], 0.15)}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 322 73"
      version="1.1"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
    >
      <path
        id="table"
        d="M322,70.487c0,-1.326 -1.077,-2.403 -2.403,-2.403l-317.194,0c-1.326,0 -2.403,1.077 -2.403,2.403l0,0.013c0,1.326 1.077,2.403 2.403,2.403l317.194,0c1.326,0 2.403,-1.077 2.403,-2.403l0,-0.013Z"
        fill="#ddba7b"
      />
      <g id="Group_205">
        <path
          id="Path_871"
          d="M116,-0.097l89,0c2.194,0 4,1.81 4,4.011l0,57.152c0,2.2 -1.806,4.01 -4,4.01l-89,0c-2.194,0 -4,-1.81 -4,-4.01l0,-57.152c0,-2.201 1.806,-4.011 4,-4.011Z"
          fill="#bfbfbf"
          fillRule="nonzero"
        />
        <path
          id="Rectangle_124"
          d="M209,66.074c0,-1.104 -0.896,-2 -2,-2l-93,0c-1.104,0 -2,0.896 -2,2l0,0.01c0,1.104 0.896,2 2,2l93,0c1.104,0 2,-0.896 2,-2l0,-0.01Z"
          fill="#a7a7a7"
        />
        <path
          id="Rectangle_213"
          d="M206,4.911c0,-1.104 -0.896,-2 -2,-2l-87,0c-1.104,0 -2,0.896 -2,2l0,54.155c0,1.103 0.896,2 2,2l87,0c1.104,0 2,-0.897 2,-2l0,-54.155Z"
          fill="#ebebeb"
        />
        <ellipse
          id="Ellipse_59"
          cx="142.5"
          cy="32.49"
          rx="2.5"
          ry="2.507"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_60"
          cx="161.5"
          cy="32.49"
          rx="2.5"
          ry="2.507"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_61"
          cx="180.5"
          cy="32.49"
          rx="2.5"
          ry="2.507"
          fill="#fff"
        />
      </g>
      <g id="blue_coffee">
        <path
          id="blue_coffee_mug"
          d="M44,68.084l-6,0c-6.583,0 -12,-5.431 -12,-12.032l0,-23.061c0,-1.1 0.903,-2.005 2,-2.005l26,0c1.097,0 2,0.905 2,2.005l0,6.528c0.955,-0.338 1.969,-0.515 3,-0.512c4.936,0.004 8.996,4.075 9,9.024c-0.005,4.948 -4.065,9.018 -9,9.024c-1.053,-0.002 -2.067,-0.188 -3.009,-0.529c-0.252,6.385 -5.566,11.558 -11.991,11.558Zm12,-16.057c0.838,0.636 1.878,1.016 3,1.017c2.743,0 5,-2.263 5,-5.013c0,-2.75 -2.257,-5.013 -5,-5.013c-1.122,0.001 -2.162,0.381 -3,1.017l0,7.992Z"
          fill="#7890e2"
          fillRule="nonzero"
        />
        <path
          id="line_of_light"
          d="M33.923,38.787l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3px"
        />
        <path
          id="steam03"
          d="M46.923,17.961l0,6.236"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam02"
          d="M40.923,9.71l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam01"
          d="M34.923,14.812l0,9.389"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
      </g>
      <g id="orange_coffee">
        <path
          id="orange_coffee_mug"
          d="M273,68.084l-6,0c-6.583,0 -12,-5.431 -12,-12.032l0,-23.061c0,-1.1 0.903,-2.005 2,-2.005l26,0c1.097,0 2,0.905 2,2.005l0,6.528c0.955,-0.338 1.969,-0.515 3,-0.512c4.936,0.004 8.996,4.075 9,9.024c-0.005,4.948 -4.065,9.018 -9,9.024c-1.053,-0.002 -2.067,-0.188 -3.009,-0.529c-0.252,6.385 -5.566,11.558 -11.991,11.558Zm12,-16.057c0.838,0.636 1.878,1.016 3,1.017c2.743,0 5,-2.263 5,-5.013c0,-2.75 -2.257,-5.013 -5,-5.013c-1.122,0.001 -2.162,0.381 -3,1.017l0,7.992Z"
          fill="#e8a85e"
          fillRule="nonzero"
        />
        <path
          id="line_of_light-2"
          d="M262.923,38.787l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3px"
        />
        <path
          id="steam03-2"
          d="M275.923,17.961l0,6.236"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam02-2"
          d="M269.923,9.71l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam01-2"
          d="M263.923,14.812l0,9.389"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
      </g>
    </svg>
  )
}

export default Consulting
