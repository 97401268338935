import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const Development = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['steam01', 'steam02', 'steam03'])}
      `}
      width="100%"
      height="100%"
      viewBox="0 0 322 73"
      version="1.1"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
    >
      <path
        id="Path_111"
        d="M165,-0.097l89,0c2.194,0 4,1.81 4,4.011l0,57.152c0,2.2 -1.806,4.01 -4,4.01l-89,0c-2.194,0 -4,-1.81 -4,-4.01l0,-57.152c0,-2.201 1.806,-4.011 4,-4.011Z"
        fill="#bfbfbf"
        fillRule="nonzero"
      />
      <ellipse
        id="Ellipse_4"
        cx="209.462"
        cy="32.764"
        rx="5.958"
        ry="5.974"
        fill="#fff"
      />
      <path
        id="Rectangle_123"
        d="M258,66.074c0,-1.104 -0.896,-2 -2,-2l-93,0c-1.104,0 -2,0.896 -2,2l0,0.01c0,1.104 0.896,2 2,2l93,0c1.104,0 2,-0.896 2,-2l0,-0.01Z"
        fill="#a7a7a7"
      />
      <path
        id="Rectangle_39"
        d="M132,56.296l9,0c3.292,0 6,2.716 6,6.016l0,4.77c0,0.55 -0.451,1.002 -1,1.002l-19,0c-0.549,0 -1,-0.452 -1,-1.002l0,-4.77c0,-3.3 2.708,-6.016 6,-6.016Z"
        fill="#bfbfbf"
        fillRule="nonzero"
      />
      <path
        id="Path_121"
        d="M136.5,57.523l0,3.472"
        fill="none"
        fillRule="nonzero"
        stroke="#a7a7a7"
        strokeWidth="3px"
      />
      <path
        id="table"
        d="M322,70.487c0,-1.326 -1.077,-2.403 -2.403,-2.403l-317.194,0c-1.326,0 -2.403,1.077 -2.403,2.403l0,0.013c0,1.326 1.077,2.403 2.403,2.403l317.194,0c1.326,0 2.403,-1.077 2.403,-2.403l0,-0.013Z"
        fill="#ddba7b"
      />
      <g id="blue_coffee">
        <path
          id="blue_coffee_mug"
          d="M86,68.084l-6,0c-6.583,0 -12,-5.431 -12,-12.032l0,-23.061c0,-1.1 0.903,-2.005 2,-2.005l26,0c1.097,0 2,0.905 2,2.005l0,6.528c0.955,-0.338 1.969,-0.515 3,-0.512c4.936,0.004 8.996,4.075 9,9.024c-0.005,4.948 -4.065,9.018 -9,9.024c-1.053,-0.002 -2.067,-0.188 -3.009,-0.529c-0.252,6.385 -5.566,11.558 -11.991,11.558Zm12,-16.057c0.838,0.636 1.878,1.016 3,1.017c2.743,0 5,-2.263 5,-5.013c0,-2.75 -2.257,-5.013 -5,-5.013c-1.122,0.001 -2.162,0.381 -3,1.017l0,7.992Z"
          fill="#7890e2"
          fillRule="nonzero"
        />
        <path
          id="line_of_light"
          d="M75.923,38.787l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3px"
        />
        <path
          id="steam03"
          d="M88.923,17.961l0,6.236"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam02"
          d="M82.923,9.71l0,14.491"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
        <path
          id="steam01"
          d="M76.923,14.812l0,9.389"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3px"
        />
      </g>
    </svg>
  )
}

export default Development
