import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { PartnerImage } from './common'

import AccelsiorsImage from '../images/trusted/accelsiors.svg'
import AccelsiorsImageGray from '../images/trusted/accelsiors_gray.svg'
import CyclolabImage from '../images/trusted/cyclolab.svg'
import CyclolabImageGray from '../images/trusted/cyclolab_gray.svg'
import HatagroImage from '../images/trusted/hatagro.svg'
import HatagroImageGray from '../images/trusted/hatagro_gray.svg'
import IntersnackImage from '../images/trusted/intersnack.svg'
import IntersnackImageGray from '../images/trusted/intersnack_gray.svg'
import RedbullImage from '../images/trusted/redbull.svg'
import RedbullImageGray from '../images/trusted/redbull_gray.svg'
import Road66Image from '../images/trusted/road66.svg'
import Road66ImageGray from '../images/trusted/road66_gray.svg'
import Fly4Less from '../images/trusted/fly4less.svg'
import Fly4LessGray from '../images/trusted/fly4less_gray.svg'
import Frontex from '../images/trusted/frontex.svg'
import FrontexGray from '../images/trusted/frontex_gray.svg'
import Guriga from '../images/trusted/guriga.svg'
import GurigaGray from '../images/trusted/guriga_gray.svg'
import IProperty from '../images/trusted/iproperty.svg'
import IPropertyGray from '../images/trusted/iproperty_gray.svg'

const FlexWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Trusted = () => (
  <FlexWrap
    css={theme => css`
      padding-bottom: ${theme.margin.medium};
    `}
  >
    <FlexWrap>
      <FlexWrap>
        <PartnerImage
          src={AccelsiorsImage}
          partner="accelsiors"
          graySrc={AccelsiorsImageGray}
        />
        <PartnerImage
          src={HatagroImage}
          partner="hatagro"
          graySrc={HatagroImageGray}
        />
      </FlexWrap>
      <FlexWrap>
        <PartnerImage
          src={IntersnackImage}
          partner="intersnack"
          graySrc={IntersnackImageGray}
        />
        <PartnerImage
          src={RedbullImage}
          partner="redbull"
          graySrc={RedbullImageGray}
        />
      </FlexWrap>
    </FlexWrap>
    <FlexWrap>
      <FlexWrap>
        <PartnerImage
          src={CyclolabImage}
          partner="cyclolab"
          graySrc={CyclolabImageGray}
        />
        <PartnerImage
          src={Road66Image}
          partner="road66"
          graySrc={Road66ImageGray}
        />
      </FlexWrap>
    </FlexWrap>
    <FlexWrap>
      <FlexWrap>
        <PartnerImage
          src={Fly4Less}
          partner="fly4less"
          graySrc={Fly4LessGray}
        />
        <PartnerImage src={Frontex} partner="frontex" graySrc={FrontexGray} />
      </FlexWrap>
    </FlexWrap>
    <FlexWrap>
      <FlexWrap>
        <PartnerImage
          src={IProperty}
          partner="iproperty"
          graySrc={IPropertyGray}
        />
        <PartnerImage src={Guriga} partner="guriga" graySrc={GurigaGray} />
      </FlexWrap>
    </FlexWrap>
  </FlexWrap>
)

export default Trusted
