import React from 'react'
import styled from '@emotion/styled'

import Fade from 'react-reveal/Fade'
import { injectIntl } from 'gatsby-plugin-intl'
import { css } from '@emotion/core'
import { Service, ServiceList, ServiceListItem } from './common'
import Consulting from './illustrations/Consulting'
import Development from './illustrations/Development'
import Training from './illustrations/Training'

const FlexWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Services = ({ intl }) => {
  return (
    <FlexWrap>
      <Service
        title={intl.formatMessage({ id: 'development' })}
        illustration={
          <Development
            css={css`
              width: 100%;
            `}
          />
        }
      >
        <Fade bottom distance="24px" duration={750}>
          <ServiceList dark>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'solution_development' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'web_applications' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'landing_pages' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'mobile_applications' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'business_logics' })}
            </ServiceListItem>
          </ServiceList>
        </Fade>
      </Service>
      <Service
        title={intl.formatMessage({ id: 'consulting' })}
        illustration={
          <Consulting
            css={css`
              width: 100%;
            `}
          />
        }
      >
        <Fade bottom distance="24px" duration={750}>
          <div />
          <ServiceList dark>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'architectural_consulting' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'product_strategy' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'business_analysis' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'it_representation' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'stack_modernization' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'marketing' })}
            </ServiceListItem>
          </ServiceList>
        </Fade>
      </Service>
      <Service
        title={intl.formatMessage({ id: 'training' })}
        illustration={
          <Training
            css={css`
              width: 100%;
            `}
          />
        }
      >
        <Fade bottom distance="24px" duration={750}>
          <ServiceList dark>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'front_end' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'back_end' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'it_foundations' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'cloud_journey' })}
            </ServiceListItem>
            <ServiceListItem main>
              {intl.formatMessage({ id: 'graphql' })}
            </ServiceListItem>
          </ServiceList>
        </Fade>
      </Service>
    </FlexWrap>
  )
}

export default injectIntl(Services)
