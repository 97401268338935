import styled from '@emotion/styled'
import { injectIntl } from 'gatsby-plugin-intl'
import React from 'react'
import rating from '../lottie/rating.json'
import rocket from '../lottie/spaceship.json'
import stundets from '../lottie/students.json'
import { Stat } from './common'
import LottieIllustration from './common/LottieIllustration'
import breakpoints from '../utils/breakpoints'

const FlexWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${breakpoints.md} {
    flex-wrap: nowrap;
  }
`

const Statistics = ({ intl }) => {
  return (
    <FlexWrap>
      <Stat
        illustration={
          <LottieIllustration data={rocket} width={130} height={130} />
        }
        count={16}
        description={intl.formatMessage({ id: 'startup_launch' })}
      />
      <Stat
        illustration={
          <LottieIllustration data={rating} width={110} height={110} />
        }
        count={4.8}
        start={1}
        decimals={1}
        description={intl.formatMessage({ id: 'customer_satisfaction' })}
      />
      <Stat
        illustration={<LottieIllustration data={stundets} />}
        count={90}
        description={intl.formatMessage({ id: 'trained_personnel' })}
        appendix="+"
      />
    </FlexWrap>
  )
}

export default injectIntl(Statistics)
