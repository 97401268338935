import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import breakpoints from '../utils/breakpoints'
import Banner from '../components/Banner'
import Trusted from '../components/Trusted'
import Services from '../components/Services'
import Statistics from '../components/Statistics'
import Section from '../components/Section'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const SectionTitle = styled('div')`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.large};
  text-transform: uppercase;
  padding-bottom: 48px;
  padding-top: 48px;
  color: ${({ theme }) => theme.colors.lightGray};

  ${breakpoints.mobile} {
    padding: 24px 0;
  }

  font-weight: bold;
`

const IndexPage = ({ intl }) => {
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        keywords={intl.formatMessage({ id: 'keywords' })}
        description={intl.formatMessage({ id: 'default_description' })}
      />
      <Banner />
      <Section>
        <Services />
      </Section>
      <Section raw>
        <div
          css={theme => css`
            padding-bottom: 60px;
            max-width: 1280px;
            margin: 0 auto;
            text-align: center;
            color: ${theme.colors.gray};

            p {
              margin-bottom: 16px;
            }
          `}
        >
          <p>{intl.formatMessage({ id: 'index_1' })}</p>
          <p>{intl.formatMessage({ id: 'index_2' })}</p>
          <p>{intl.formatMessage({ id: 'index_3' })}</p>
          <p>{intl.formatMessage({ id: 'index_4' })}</p>
        </div>
      </Section>
      <Section
        alternate
        title={
          <SectionTitle
            css={css`
              padding-bottom: 0;

              ${breakpoints.mobile} {
                padding: 24px 0 8px 0;
              }
            `}
          >
            <span
              css={theme =>
                css`
                  color: ${theme.colors.accent};
                  font-weight: bold;
                `
              }
            >
              {intl.formatMessage({ id: 'trusted_by_1' })}
            </span>
            <span
              css={css`
                font-weight: normal;
              `}
            >
              {intl.formatMessage({ id: 'trusted_by_2' })}
            </span>
          </SectionTitle>
        }
      >
        <Trusted />
      </Section>
      <Section
        title={
          <SectionTitle>
            {intl.formatMessage({ id: 'statistics' })}
          </SectionTitle>
        }
      >
        <Statistics />
      </Section>
    </Layout>
  )
}

export default injectIntl(IndexPage)
