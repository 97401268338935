import React from 'react'
import { css } from '@emotion/core'

const Section = ({ children, alternate, title, raw }) => (
  <section
    css={theme => css`
      background: ${alternate ? theme.colors.fullWhite : theme.colors.white};

      :last-child {
        padding-bottom: 0px;
      }
    `}
  >
    {title && title}
    <div
      css={css`
        padding: 0 24px;
        ${!title && !raw && 'padding-top: 64px'};
        max-width: 1920px;
        margin: 0 auto;
      `}
    >
      {children}
    </div>
  </section>
)

export default Section
