import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const Training = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['steam01', 'steam02', 'steam03'])}
        ${steamAnimation(['steam01-2', 'steam02-2', 'steam03-2'], 0.15)}
        ${steamAnimation(['steam01-3', 'steam02-3', 'steam03-3'], 0.4)}
      `}
      width="100%"
      height="100%"
      viewBox="0 0 322 73"
      version="1.1"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
    >
      <path
        id="table"
        d="M322,70.289c0,-1.326 -1.077,-2.403 -2.403,-2.403l-317.194,0c-1.326,0 -2.403,1.077 -2.403,2.403l0,0.036c0,1.327 1.077,2.403 2.403,2.403l317.194,0c1.326,0 2.403,-1.076 2.403,-2.403l0,-0.036Z"
        fill="#ddba7b"
      />
      <g id="Group_206">
        <path
          id="Rectangle_128"
          d="M103.76,1.961c0.213,-0.8 -0.263,-1.625 -1.062,-1.841l-0.002,0c-0.799,-0.216 -1.621,0.258 -1.834,1.058l-16.581,62.308c-0.213,0.8 0.263,1.625 1.062,1.841l0.002,0c0.799,0.216 1.621,-0.258 1.834,-1.058l16.581,-62.308Z"
          fill="#bfbfbf"
        />
        <path
          id="Rectangle_127"
          d="M88,65.857c0,-1.104 -0.896,-2 -2,-2l-66,0c-1.104,0 -2,0.896 -2,2l0,0.029c0,1.104 0.896,2 2,2l66,0c1.104,0 2,-0.896 2,-2l0,-0.029Z"
          fill="#a7a7a7"
        />
      </g>
      <g id="orange_coffee">
        <path
          id="orange_coffee_mug"
          d="M275,67.886l-6,0c-6.583,0 -12,-5.457 -12,-12.089l0,-23.173c0,-1.105 0.903,-2.015 2,-2.015l26,0c1.097,0 2,0.91 2,2.015l0,6.559c0.955,-0.339 1.969,-0.516 3,-0.514c4.936,0.005 8.996,4.095 9,9.068c-0.005,4.972 -4.065,9.062 -9,9.067c-1.053,-0.001 -2.067,-0.189 -3.009,-0.531c-0.252,6.415 -5.566,11.613 -11.991,11.613Zm12,-16.134c0.838,0.639 1.878,1.02 3,1.022c2.743,0 5,-2.274 5,-5.037c0,-2.764 -2.257,-5.038 -5,-5.038c-1.122,0.002 -2.162,0.383 -3,1.022l0,8.031Z"
          fill="#e8a85e"
          fillRule="nonzero"
        />
        <path
          id="line_of_light"
          d="M264.923,38.449l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3.01px"
        />
        <path
          id="steam03"
          d="M277.923,17.522l0,6.265"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam02"
          d="M271.923,9.231l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam01"
          d="M265.923,14.357l0,9.434"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
      </g>
      <g id="orange_coffee-2">
        <path
          id="orange_coffee_mug-2"
          d="M214,67.886l-6,0c-6.583,0 -12,-5.457 -12,-12.089l0,-23.173c0,-1.105 0.903,-2.015 2,-2.015l26,0c1.097,0 2,0.91 2,2.015l0,6.559c0.955,-0.339 1.969,-0.516 3,-0.514c4.936,0.005 8.996,4.095 9,9.068c-0.005,4.972 -4.065,9.062 -9,9.067c-1.053,-0.001 -2.067,-0.189 -3.009,-0.531c-0.252,6.415 -5.566,11.613 -11.991,11.613Zm12,-16.134c0.838,0.639 1.878,1.02 3,1.022c2.743,0 5,-2.274 5,-5.037c0,-2.764 -2.257,-5.038 -5,-5.038c-1.122,0.002 -2.162,0.383 -3,1.022l0,8.031Z"
          fill="#e8a85e"
          fillRule="nonzero"
        />
        <path
          id="line_of_light-2"
          d="M203.923,38.449l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3.01px"
        />
        <path
          id="steam03-2"
          d="M216.923,17.522l0,6.265"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam02-2"
          d="M210.923,9.231l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam01-2"
          d="M204.923,14.357l0,9.434"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
      </g>
      <g id="orange_coffee-3">
        <path
          id="orange_coffee_mug-3"
          d="M153,67.886l-6,0c-6.583,0 -12,-5.457 -12,-12.089l0,-23.173c0,-1.105 0.903,-2.015 2,-2.015l26,0c1.097,0 2,0.91 2,2.015l0,6.559c0.955,-0.339 1.969,-0.516 3,-0.514c4.936,0.005 8.996,4.095 9,9.068c-0.005,4.972 -4.065,9.062 -9,9.067c-1.053,-0.001 -2.067,-0.189 -3.009,-0.531c-0.252,6.415 -5.566,11.613 -11.991,11.613Zm12,-16.134c0.838,0.639 1.878,1.02 3,1.022c2.743,0 5,-2.274 5,-5.037c0,-2.764 -2.257,-5.038 -5,-5.038c-1.122,0.002 -2.162,0.383 -3,1.022l0,8.031Z"
          fill="#e8a85e"
          fillRule="nonzero"
        />
        <path
          id="line_of_light-3"
          d="M142.923,38.449l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#fff"
          strokeOpacity="0.5"
          strokeWidth="3.01px"
        />
        <path
          id="steam03-3"
          d="M155.923,17.522l0,6.265"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam02-3"
          d="M149.923,9.231l0,14.56"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
        <path
          id="steam01-3"
          d="M143.923,14.357l0,9.434"
          fill="none"
          fillRule="nonzero"
          stroke="#dbdbdb"
          strokeWidth="3.01px"
        />
      </g>
    </svg>
  )
}

export default Training
