import React from 'react'
import Lottie from 'react-lottie'
import banner from '../lottie/banner.json'

const LottieName = ({ ...props }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: banner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div {...props}>
      <Lottie options={defaultOptions} />
    </div>
  )
}

export default LottieName
