import React, { useState, useEffect, useRef } from 'react'
import Lottie from 'react-lottie'
import useVisibilitySensor from '@rooks/use-visibility-sensor'
import { css } from '@emotion/core'

const LottieIllustration = ({ data, height = 120, width = 120 }) => {
  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [loopCompleted, setLoopCompleted] = useState(false)
  const [firstVisibility, setFirstVisibility] = useState(false)

  const rootNode = useRef(null)
  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    scrollDebounce: 50,
    partialVisibility: true,
  })

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (isVisible && !firstVisibility) {
      setPlaying(true)
      setLoopCompleted(false)
      setFirstVisibility(true)
    }
  }, [firstVisibility, isVisible])

  useEffect(() => {
    if (hover) {
      setPlaying(true)
      setLoopCompleted(false)
    } else if (!hover && loopCompleted) {
      setPlaying(false)
    }
  }, [hover, loopCompleted])

  return (
    <div
      ref={rootNode}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      css={css`
        height: 100% !important;
      `}
    >
      <Lottie
        height={height}
        width={width}
        isStopped={!playing}
        options={defaultOptions}
        isClickToPauseDisabled
        eventListeners={[
          {
            eventName: 'loopComplete',
            callback: () => setLoopCompleted(true),
          },
        ]}
      />
    </div>
  )
}

export default LottieIllustration
